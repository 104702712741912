<template>
  <div>
    <el-row>
      <el-button type="primary" icon="el-icon-refresh" @click="getList()">刷新</el-button>
    </el-row>
    <el-table :data="tableData" v-loading="tableLoading">
      <el-table-column label="设备类型" prop="deviceType"></el-table-column>
      <el-table-column label="序列号" prop="serialNumber"></el-table-column>
      <el-table-column label="连接时间" prop="startTime"></el-table-column>
      <el-table-column label="最后通讯时间" prop="lastTime"></el-table-column>
      <el-table-column label="出厂日期" prop="dateOfProduction"></el-table-column>
      <el-table-column label="版本号" prop="versionNumber"></el-table-column>
      <el-table-column label="Ip" prop="ip"></el-table-column>
      <el-table-column label="发送次数" prop="sendTimes"></el-table-column>
      <el-table-column label="接收次数" prop="receiveTimes"></el-table-column>
    </el-table>
  </div>
</template> 

<script>
export default {
  data() {
    return {
      tableData: null,
      tableLoading: false,
    };
  },
  methods: {
    getList() {
      let that = this;
      that.tableLoading = true;
      that.axios.post("Base_Device/GetOnLineDevice1").then(function (response) {
        that.tableLoading = false;
        that.tableData = eval("(" + response.data.data + ")");
      });
    },
  },
  mounted() {
    let that = this;
    that.getList();
  },
};
</script>

<style>
</style>